import React, { useEffect, useRef, useState } from 'react';
import { Button, Groupper, Header, Input, Message } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import { Modal } from 'semantic-ui-react';
import { bindChange, bindClose, bindFormChange } from '@arema/components/Util';
import { CAPTCHA_ACTIVE, RECAPTCHA } from '../PublicConfig';
import { Link } from 'react-router-dom';
import { SetLoading } from '@arema/components/Classes';
import ReCAPTCHA from 'react-google-recaptcha';
import Validator from '@arema/components/Validator';
import API from '../PublicAPI';

import '../style/mytickets.scss';

interface SearchForm{
	order_hash: string,
	email: string,
}

var Mytickets = ()=>{
	var { setTitle } = useTitle();
	var captchaRef = useRef<ReCAPTCHA>(null);
	var [searchModal, setSearchModal] = useState<boolean>(null);
	var [captcha, setCaptcha] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [ticketUrl, setTicketUrl] = useState<string>(null);
	var [searchForm, setSearchForm] = useState<SearchForm>({
		order_hash: '',
		email: '',
	});

	useEffect(()=>{
		setTitle('Mis compras')
	}, []);

	var showSearchModal = ()=>{
		setTicketUrl(null);
		setErrorPrompts(null);
		setSearchForm({
			order_hash: '',
			email: '',
		})
		setSearchModal(true);
	}

	var searchTickets = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(searchForm, {
			order_hash: [
				{ rule: /[0-9a-z]{4}/gi, prompt: 'El folio de compra no es válido' }
			],
			email: [
				{ rule: 'email', prompt: 'El correo electrónico no es válido' }
			]
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.searchTickets(searchForm.order_hash, searchForm.email, captcha).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data.tickets_url){
				setTicketUrl(res.data.tickets_url);
			}else{
				setErrorPrompts(['Hubo un error buscando los boletos (LCL-2)'])
			}
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado buscando los boletos (LCL-1)'])
		}).finally(()=>{
			setLoading(false);
			captchaRef.current?.reset();
		});
	}

	var onFormChange = bindFormChange(searchForm, setSearchForm);
	
	return <div className="fr mytickets-screen">
		<Header text='Mis compras' style={{ marginTop: 15 }} />
		<Groupper width={500} title='Recuperar boletos' titleCentered>
			<p>Si cuenta con su número confirmación y necesita de nuevo el documento impreso puede utilizar la siguiente herramienta</p>
			<Button color='orange' text='Recuperar boletos' size='big' iconName='file-pdf' onClick={showSearchModal} />
		</Groupper>
		<Groupper width={500} title='Facturación' titleCentered>
			<p> AREMA Ticket facturará solamente el cargo por servicio de tus boletos.</p>
			<Button color='orange' text='Facturación' size='big' iconName='file-text' as={Link} to={'/facturar'} />
		</Groupper>
		
		<Modal size='mini' open={searchModal} onClose={bindClose(setSearchModal)}>
			<Modal.Header>Recuperar boletos</Modal.Header>
			<Modal.Content>
				<Input label='Folio de compra' value={searchForm.order_hash} onChange={onFormChange('order_hash')} />
				<Input label='Correo electrónico' comment='El correo electrónico con el cual se compró los boletos' onChange={onFormChange('email')} />
				{!!CAPTCHA_ACTIVE && (
					<ReCAPTCHA 
						ref={captchaRef}
						hl='es-419'
						sitekey={RECAPTCHA}
						onChange={setCaptcha}
						onExpired={()=>setCaptcha(null)}
					/>
				)}
				{!!ticketUrl && (
					<Message type='info' centered>
						<div className="header">Descargar boletos</div>
						¡Encontramos tus boletos! Puedes descargarlos en el siguiente enlace
						<Button color='blue' style={{ marginTop: 10, width: 200 }} text='Ver boletos' iconName='file-pdf' as={Link} to={ticketUrl} target={'_blank'} />
					</Message>
				)}
				<Message list={errorPrompts} type='error' />
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setSearchModal)} />
				<Button text='Buscar' color='black' onClick={searchTickets} disabled={!!ticketUrl} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default Mytickets;