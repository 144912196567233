import React, { useEffect, useState } from 'react';
import { Accordion, Button, Groupper, Header, Message } from 'react-frontier';
import { Link } from 'react-router-dom';

var RefundsInfo = ()=>{
	return <div>
		<Header text='Reembolsos' style={{ marginTop: 15 }} />
		<Message centered style={{ maxWidth: 500, margin: 'auto' }}>
			Le recordamos que AREMA Ticket en ningún caso es responsable de la programación de eventos ni de la cancelación o cambio de fecha de los mismos. 
			Actuamos como un facilitador de la distribución de las entradas y trabajaremos para que los reembolsos de las ventas mediante nuestro sistema sean lo más ágiles posible. 
		</Message>

		<Header text='Solicita tu reembolso' style={{ marginTop: 30 }} subtext='Necesitarás la información de tu compra' />
		<Button text='Crear solicitud de reembolso' color='orange' style={{ display: 'block', width: 340, margin: 'auto', fontSize: 20, padding: '15px 30px' }} as={Link} to={'/reembolsar'} />

		<Header text='Preguntas frecuentes' style={{ marginTop: 30, marginBottom: 20 }} />
		<Groupper fitted style={{ overflow: 'hidden' }} width={800}>
			<Accordion divided selectable>
				<Accordion.Item bold title={"Envié mi solicitud de reembolso pero no recibí confirmación"}>
					<p>
						Para agilizar el trámite y optimizar tiempos, no respondemos de recibido los correos. Una vez que recibe la respuesta sobre la gestión de su solicitud, significa que su reembolso ya está en proceso. 
					</p>
				</Accordion.Item>
				<Accordion.Item title={"¿Cuánto tiempo tarda el proceso de devolución?"} bold>
					<p>
						Nuestros compañeros atenderán lo antes posible las solicitudes de reembolso en el orden que sean recibidas. Regularmente los tiempos son de 7 a 10 días hábiles (de lunes a viernes) pero pueden incrementarse cuando la demanda de reembolsos es alta en razón del número de boletos vendidos hasta antes de la cancelación/posposición del evento.
						<br /> <br />
						Una vez que nosotros hayamos procesado el reembolso, nuestro banco lo regresa al suyo (el banco emisor de la tarjeta). Sin embargo, algunos bancos tardan varios días en reflejar estos movimientos en su estado de cuenta y saldo, en algunos casos lo reflejan hasta el siguiente estado de cuenta. Desafortunadamente, no podemos hacer nada para acelerar esta parte del proceso. 
					</p>
				</Accordion.Item>
				<Accordion.Item title={"Ya pasaron los días que me dijeron y no me han devuelto mi dinero, ¿significa que no me lo regresarán?"} bold>
					<p>
						No. Su dinero está seguro y su reembolso garantizado. Si pasados los días promedio no ha recibido la notificación de su reembolso, es sólo una cuestión de tiempo (ver punto 2). 
					</p>
				</Accordion.Item>
				<Accordion.Item title={"Ya recibí la notificación de AREMA Ticket sobre mi reembolso, ¿por qué no lo veo reflejado en mi cuenta?"} bold>
					<p>
						Una vez que AREMA Ticket envía la notificación de que su dinero se ha devuelto, nuestro banco lo regresa al suyo (el banco emisor de la tarjeta).
						<br /><br />
						Sin embargo, algunos bancos tardan varios días en reflejar estos movimientos en su estado de cuenta y saldo, en algunos casos lo reflejan hasta el siguiente estado de cuenta. Desafortunadamente, no podemos hacer nada para acelerar esta parte del proceso. 
					</p>
				</Accordion.Item>
				<Accordion.Item title={"Recibí la notificación de mi reembolso, pero no aparece en mi cuenta. Llamé a mi banco y me dicen que no tienen ningún reembolso pendiente, ¿qué procede?"} bold>
					<p>
						Una vez que AREMA Ticket libera el monto del reembolso, el resto del proceso depende por completo de su banco. En nuestra experiencia, los ejecutivos de las sucursales bancarias no tienen herramientas en sus sistemas para darle seguimiento a este tipo de movimientos interbancarios, por lo que es muy normal que le digan que “no ven” el reembolso.
						<br /><br />
						Para identificar más claramente el movimiento con los bancos, es recomendable usar el número de autorización de la operación que generó su banco 
					</p>
				</Accordion.Item>
				<Accordion.Item title={"Me devolvieron menos de lo que pagué, ¿por qué? "} bold>
					<p>
						Los cargos por los servicios que prestamos no son reembolsables. Al comprar boletos en nuestra página o puntos de venta, se están aceptando las condiciones del servicio. 
					</p>
				</Accordion.Item>
			</Accordion>
		</Groupper>
	</div>
}

export default RefundsInfo;