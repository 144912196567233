import React, { useEffect, useState } from 'react';
import { Button, Groupper, Header, Image, Calendar, Message, Input } from 'react-frontier';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { CDN_URL, PAGE_CHECKOUT, PLACEHOLDER_IMG, CAPTCHA_ACTIVE, RECAPTCHA } from '../PublicConfig';
import { Modal } from 'semantic-ui-react';
import { EventType, PublicEvent as PublicEventClass, SetLoading } from '@arema/components/Classes';
import { bindClose, getDateStatusLocale, groupBy } from '@arema/components/Util';
import { firstBy } from 'thenby';
import { useTitle }  from '@arema/components/Hooks'
import { EventHeader, NotFound } from '../components';
import { Countdown } from '@arema/components';
import API from '../PublicAPI';
import moment from 'moment';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import Validator from '@arema/components/Validator';

import '../style/event.scss';

interface PublicEventProps{
	legacy?: boolean,
	event_id?: number,
	subdomain?: string,
	placeholder?: boolean,
}

const LEGACY_EVENTS_THRESHOLD = 5000;
const LEGACY_URL = process.env.REACT_APP_LEGACY_CHECKOUT;

var PublicEvent = (props: PublicEventProps)=>{
	var [params, setParams] = useSearchParams();
	var { event: param_event_id } = useParams();
	var { setTitle } = useTitle();
	var access_token = params.get('ac');
	
	var [loadError, setLoadError] = useState<string>(null);
	var [imageModal, setImageModal] = useState<boolean>(false);
	var [selectedDate, setSelectedDate] = useState<number>(null);
	var [event, setEvent] = useState<PublicEventClass>(null);
	var [notFound, setNotFound] = useState<boolean>(null);
	var [accessCode, setAccessCode] = useState<string>('');
	var [captcha, setCaptcha] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);

	var getEventId = (from_event: boolean=true)=>{
		var event_id : number = null
		if(event && from_event) return event.event_id;
		if(param_event_id && !Number.isNaN(parseInt(param_event_id))){
			event_id = parseInt(param_event_id)
		}else if(props.event_id && !Number.isNaN(parseInt(props.event_id.toString()))){
			event_id = parseInt(props.event_id.toString());
		}
		return event_id;
	}

	var event_id = getEventId();
	var is_legacy = props.legacy || (event_id && event_id>=LEGACY_EVENTS_THRESHOLD);

	var getEventData = (force: boolean=false)=>{
		var event_id = getEventId(false);
		var is_legacy = props.legacy || (event_id && event_id>=LEGACY_EVENTS_THRESHOLD);
		setNotFound(false);
		setEvent(null);

		var access : string = null;
		try{
			var access = params.get('ac');
			if(!access || access.length<5) access = null;
		}catch(e){
			access = null;
		}

		if(!is_legacy){
			API.getEvent(event_id, null, access, force).then(res=>{
				if(res.error) return setLoadError(res.message);
				if(!res.data) return setNotFound(true);
				setEvent(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando el evento. (PLCL-EVGT-1)');
			})
		}else{
			API.getLegacyEvent(event_id, access, force).then(res=>{
				if(res.error) return setLoadError(res.message);
				if(!res.data) return setNotFound(true);
				setEvent(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando el evento. (PLCL-EVGT-2)');
			})
		}
	}

	var sendAccessCode = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({ accessCode, captcha }, {
			accessCode: [{
				rule: 'minLength', params: [3], prompt: 'El código de acceso es inválido',
			}],
			captcha: [{
				rule: 'empty', prompt: 'Favor de completar el captcha', if: !!CAPTCHA_ACTIVE
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
	
		setLoading(true);
		API.getAccessCode(getEventId(), accessCode, captcha, is_legacy).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			params.set('ac', res.data.token);
			getEventData(true);
			setParams(params);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error enviando el código de acceso (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	useEffect(()=>{
		var event_id = getEventId(false);
		var token = API.getAccessToken(event_id);
		var params_code = params.get('ac');
		if((!event && !!event_id) || (event_id!==event?.event_id)){
			getEventData();
		}
		if(token){
			if(!!event_id && !params_code){
				params.set('ac', token);
				setParams(params);
			}
		}else if(!!event_id && params_code){
			API.setAccessToken(event_id, params_code);
		}
	}, [props, param_event_id]);

	useEffect(()=>{
		if(event){
			setTitle(event.event_name);
		}
	}, [event]);

	if(!event_id || notFound){
		return <NotFound />
	}
	if(loadError){
		return <Header
			containerStyle={{ marginTop: 20 }}
			text='Error'
			iconName='face-frown-open'
			subtext={loadError}
		/>
	}

	var EventDate = (props: { date?: PublicEventClass["dates"][0], placeholder?: boolean })=>{
		if(props.placeholder){
			return <div className="ar date placeholder">
				<div className="left">
					<div className="day"></div>
					<div className="month"></div>
				</div>
				<div className="right">
					<div className="time"></div>
					<div className="venue"></div>
				</div>
			</div>
		}

		var a = props.date;
		var mdate = moment.unix(a.date);
		return (
			<Link className={classNames('ar date', {
				inactive: !a.active
			})} key={`date-${a.date_id}`} to={`${is_legacy ? LEGACY_URL : PAGE_CHECKOUT}/${a.date_id}${access_token ? `?ac=${access_token}` : ''}`}>
				<div className="left">
					<div className="day">{mdate.format('DD')}</div>
					<div className="month">{mdate.format('MMM YYYY')}</div>
				</div>
				<div className="right">
					{!a.active && (
						<div className="status">{getDateStatusLocale(a.status_id)}</div>
					)}
					<div className="time">{mdate.format('HH:mm')}</div>
					<div className="venue">{a.venue_name}</div>
				</div>
			</Link>
		)
	}

	var EventDateList = (props: { dates: PublicEventClass["dates"] })=>(
		<>
			{groupBy(props.dates.sort(firstBy('date', 'asc')), 'city').map(city=>(
				<React.Fragment key={`city-${city.city}`}>
					<div className="section top">{city.city}</div>
					{city.values.map(a=>(
						<EventDate key={`EVTDT-${a.date_id}`} date={a} />
					))}
				</React.Fragment>
			))}
		</>
	)

	var selected_mdate = selectedDate ? moment.unix(selectedDate) : null;
	var active_date = !event || !event.date_start || event.date_start<moment().unix();
	var needs_code = event?.access_code;
	var show_dates = active_date && !needs_code;

	return <>
		{!active_date && <>
			<Header text='Inicio de venta en' style={{ marginTop: 15, marginBottom: 0 }} />
			<Countdown days separate 
				className='ar event-countdown' 
				unix={event.date_start}
				onFinish={()=>getEventData(true)}
				labels={{
					days: 'Días',
					hours: 'Horas',
					minutes: 'Minutos',
					seconds: 'Segundos',
				}}
			/>
		</>}
		<EventHeader
			poster={(event && event.poster) ? event.poster : (event_id ? `${CDN_URL}/events/${event_id}/800.webp` : null)}
			data={event ? {
				name: event.event_name,
				cities: event.dates.reduce((a,b)=>([...a, b.city]), []),
				sinopsis: event.sinopsis
			} : null}
			onClickImage={bindClose(setImageModal, true)}
		/>
		{!!needs_code && (
			<Groupper width={400} title={'Código de acceso'} actions={(
				<Button color='black' text='Continuar' onClick={sendAccessCode} />
			)}>
				<Message type='info' centered>
					Este evento requiere de un código de acceso para comprar. Favor de ingresarlo para continuar
				</Message>
				<Input label='Código de acceso' value={accessCode} onChange={setAccessCode} style={{ textAlign: 'center', marginTop: 15 }} inputStyle={{ fontSize: 16, textAlign: 'center' }} />
				{!!CAPTCHA_ACTIVE && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<ReCAPTCHA 
							hl='es-419'
							sitekey={RECAPTCHA}
							onChange={setCaptcha}
							onExpired={()=>setCaptcha(null)}
						/>
					</div>
				)}
				<Message list={errorPrompts} type='error' style={{ marginTop: 15 }} />
			</Groupper>
		)}
		{!show_dates ? (
			null
		) : event && event.dates.length==0 ? (
			<Header iconName='ticket' text='Sin funciones' subtext='No hay funciones disponibles para este evento.'  />
		) : (
			<Groupper 
				title="Funciones" 
				defaultStyle={false}
				titleRight={event?.event_type===EventType.CALENDAR && !!selectedDate && (
					<Button size='tiny' color='black' onClick={bindClose(setSelectedDate, null)} text='Regresar' iconName='arrow-left' />
				)}
				className={classNames('dates', {
					cal: event?.event_type===EventType.CALENDAR
				})}
			>
				{event ? (
					event.event_type===EventType.CALENDAR ? (
						!selectedDate ? (
							<Calendar
								limitActiveMonths
								date={null}
								mode='date'
								locale='es'
								activeDays={event.dates.map(a=>a.date)}
								onSelected={setSelectedDate}
							/>
						) : (
							<EventDateList dates={event.dates.filter(a=>(
								moment.unix(a.date).isSame(selected_mdate, 'day')
							))} />	
						)
					) : (
						<EventDateList dates={event.dates} />
					)
				) : (
					<>
						<div className="section top">
							<div className="placeholder"></div>
						</div>
						{new Array(5).fill('AREMA').map((a,i)=>(
							<EventDate placeholder key={`DTPLH-${i}`} />
						))}
					</>
				)}
			</Groupper>
		)}
		<Modal basic open={imageModal} onClose={bindClose(setImageModal, false)} style={{ width: 'auto' }} className='show-image'>
			<Modal.Content image>
				<Image 
					src={event?.poster || `${CDN_URL}/events/${(event?.event_id || event_id)}/800.webp`}
					fallback={PLACEHOLDER_IMG}
					style={{ display: 'block', margin: 'auto', borderRadius: 12 }} 
				/>
			</Modal.Content>
			<Modal.Actions>
				<div style={{ textAlign: 'center' }}>
					<Button text='Cerrar' color='white' size='big' onClick={bindClose(setImageModal, false)} />
				</div>
			</Modal.Actions>
		</Modal>
	</>
}

export default PublicEvent;